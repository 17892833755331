import { motion } from 'framer-motion';
import { Lethargy } from 'lethargy';
import { useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import Competences from '../Slides/Competences';
import Geo from '../Slides/Geo2';
import Intro from '../Slides/Intro';
import Partners from '../Slides/Partners';
import Production from '../Slides/Production';
import styles from './desktop.module.scss';
import { useTranslation } from 'next-i18next';

const lethargy = new Lethargy();

const clamp = (value, min, max) => Math.max(Math.min(max, value), min);

const Main = ({ touch = false, pageData }) => {
  const { t } = useTranslation(['common', 'main']);
  const [index, setIndex] = useState(0);

  const slides = [
    { id: 0, anchor: t('Старт'), component: Intro },
    { id: 1, anchor: '01', component: Geo },
    { id: 2, anchor: '02', component: Production },
    { id: 3, anchor: '03', component: Competences },
    { id: 4, anchor: '04', component: Partners },
  ];

  const transitions = useTransition(index, {
    key: index,
    from: {
      opacity: 0,
      zIndex: '-1',
    },
    enter: {
      opacity: 1,
      zIndex: '1',
      delay: 600,
    },
    leave: {
      opacity: 0,
      zIndex: '-1',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
      paddingLeft: 'inherit',
      paddingRight: 'inherit',
    },
    // config: config.slow,
    config: { duration: 700 },
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = null;
    };
  }, []);

  const bind = useGesture({
    onWheel: ({ event, last, memo: wait = false }) => {
      if (!last) {
        const s = lethargy.check(event);
        if (s) {
          if (!wait) {
            setIndex(i => {
              const cl = clamp(i - s, 0, slides.length - 1);
              return cl;
            });
            return true;
          }
        } else return false;
      } else {
        return false;
      }
    },
    onDrag: ({ last, active, movement: [mx, my], direction: [xDir, yDir], distance }) => {
      if (touch) {
        if (!last) {
          if (distance > 10) {
            setIndex(i => {
              const cl = clamp(i + (yDir > 0 ? -1 : 1), 0, slides.length - 1);
              return cl;
            });
          }
        }
      }
    },
  });

  return (
    <div className={styles.wrapper} {...bind()}>
      <div className={styles.container}>
        <motion.div
          className={styles.indicators}
          initial={{
            opacity: 0,
            x: '50%',
            transition: { duration: 0.3 },
          }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { duration: 0.3, delay: 0.8 },
          }}
          exit={{
            opacity: 0,
            x: '50%',
            transition: { duration: 0.3 },
          }}
        >
          <div className={styles.iSlides}>
            <Track per={((index + 1) / slides.length) * 100} />
            {slides.map((s, k) => {
              const delay = k * 0.2 + 1;
              return (
                <motion.div
                  className={styles.i}
                  key={s.anchor}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 0.3, delay },
                  }}
                >
                  <span onClick={() => setIndex(k)}>{s.anchor}</span>
                </motion.div>
              );
            })}
          </div>
        </motion.div>
        {transitions((style, i) => {
          if (slides[i]) {
            const Comp = slides[i].component;
            return (
              <animated.div className={styles.slide} key={i} style={{ ...style }}>
                <Comp key={i} pageData={pageData} index={index} setIndex={setIndex} />
              </animated.div>
            );
          }
        })}
      </div>
    </div>
  );
};

const Track = ({ per }) => {
  return (
    <>
      <div className={styles.track} />
      <div className={styles.thumb} style={{ height: `${per}%` }} />
    </>
  );
};

export default Main;
